import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, { GetEndpointInjector } from '../api/httpClient';
import { Options } from './paging';
import { handleErrorResponse } from '../utils/utils';
import { logger } from '../api/logger';
import { getAccessToken } from './utils';

export type MetricBase = {
  completed_orders_count: number;
  clients_count: number;
  products_count: number;
  active_orders_count: number;
};

export const valid = (metric: { [x: string]: string | number }) => {
  const keys = Object.keys(metric);
  let res = true;
  keys.forEach((k) => {
    if (Number.isInteger(metric[k])) {
      res = res && metric[k] !== 0;
    } else {
      res = res && metric[k] !== '';
    }
  });

  return res;
};

export const getMetrics = createAsyncThunk(
  'metrics/get/all',
  async (item: GetEndpointInjector<Options>, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/metrics`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      rejectWithValue(err);
    }
  }
);

type ErrorResponse = {
  message: string;
};

type MetricApiState = {
  metrics?: MetricBase | null;
  page: number;
  limit: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: MetricApiState = {
  metrics: undefined,
  status: 'idle',
  page: 1,
  limit: 10,
  error: null
};

const metricSlice = createSlice({
  name: 'metric',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.metrics = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      logger.debug(action.payload);
      state.limit = action.payload;
      state.page = 1;
      state.metrics = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetrics.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getMetrics.fulfilled, (state, action: PayloadAction<MetricBase>) => {
        state.status = 'idle';
        state.metrics = action.payload;
      })
      .addCase(getMetrics.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = metricSlice.actions;
export const { setLimit } = metricSlice.actions;
export default metricSlice.reducer;
