abstract class BaseError extends Error {
  public abstract readonly type: string;
}

export const isBaseError = <T extends BaseError>(e: unknown): e is T => e instanceof BaseError;

export class MissingAccessTokenError extends BaseError {
  type = 'MISSING_ACCESS_TOKEN_ERROR';
}

export class MalformedAPIResponseError extends BaseError {
  type = 'MALFORMED_API_RESPONSE_ERROR';
}
