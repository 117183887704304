import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import httpClient, {
  GetEndpointInjector,
  GetOneEndpointInjector,
  PostEndpointInjector,
  PutEndpointInjector
} from '../api/httpClient';
import { handleErrorResponse } from '../utils/utils';
import { buildPagingOptions, Options, Paginated } from './paging';
import { NavigateFunction } from 'react-router-dom';
import { getAccessToken } from './utils';

export type User = {
  user_id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export const postUser = createAsyncThunk(
  'user/post',
  async (product: PostEndpointInjector<User>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/users`, product.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: product.navigate });
      return err;
    }
  }
);

export const putUser = createAsyncThunk(
  'user/put',
  async (product: PutEndpointInjector<User>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.put(`/users/${product.id}`, product.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: product.navigate });
      return err;
    }
  }
);

export const getUsers = createAsyncThunk(
  'user/get/all',
  async (deps: GetEndpointInjector<Options>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/users?${buildPagingOptions(deps.options)}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getUsersByClient = createAsyncThunk(
  'user/get/mul/by/client',
  async (deps: GetEndpointInjector<{ product_id: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/search/users?client_id=${deps.options?.product_id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const searchUsers = createAsyncThunk(
  'user/search',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/users?search_word=${deps.options?.word ?? ''}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getUser = createAsyncThunk(
  'user/get',
  async (deps: GetOneEndpointInjector, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/users/${deps.id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const whoAmI = createAsyncThunk(
  'user/whoAmI',
  async (deps: { navigate: NavigateFunction }, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/whoami`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

type ErrorResponse = {
  message: string;
};

type UserApiState = {
  users?: User[] | null;
  user?: User | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: UserApiState = {
  users: undefined,
  status: 'idle',
  page: 1,
  count: 0,
  limit: 10,
  error: null
};

const userSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.users = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.users = undefined;
    },
    resetUserState(state) {
      state.user = undefined;
      state.users = undefined;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<Paginated<User>>) => {
        state.status = 'idle';
        state.users = action.payload.items;
        state.count = action.payload.count;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getUsersByClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUsersByClient.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(getUsersByClient.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchUsers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.status = 'idle';
        state.users = action.payload;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(whoAmI.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(whoAmI.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(whoAmI.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = undefined;
        state.users = undefined;
      })
      .addCase(postUser.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(putUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = undefined;
        state.users = undefined;
      })
      .addCase(putUser.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = userSlice.actions;
export const { setLimit } = userSlice.actions;
export const { resetUserState } = userSlice.actions;

export default userSlice.reducer;
