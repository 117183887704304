import { Box, TextField, Button, MenuItem } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import {
  getClient,
  postClient,
  putClient,
  resetClientState,
  setLimit,
  setPage
} from '../slices/clientSlice';
import { useNavigate, useParams } from 'react-router-dom';

const Client = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useAppSelector((state) => state.client);
  const [newClient, setNewClient] = useState(client);

  const validateFields = () => {
    if (!newClient.gov_id.trim()) {
      dispatch(
        showNotification({
          message: 'El campo "Gov Id" es obligatorio',
          type: NotificationType.Error
        })
      );
      return false;
    }

    if (!newClient.name.trim() || newClient.name.length <= 5) {
      dispatch(
        showNotification({
          message: 'El campo "Nombre" es obligatorio y debe tener más de 5 caracteres',
          type: NotificationType.Error
        })
      );
      return false;
    }
    return true;
  };

  const handleClientCreation = async () => {
    if (!validateFields()) return;

    try {
      await dispatch(
        postClient({
          body: newClient,
          navigate
        })
      ).unwrap();

      setPage(1);
      setLimit(10);
      setNewClient(client);
      navigate('/clients');
    } catch (e) {
      console.error(e);
    }
  };

  const handleClientUpdating = async () => {
    if (!validateFields()) return;

    if (clientId) {
      try {
        await dispatch(
          putClient({
            id: clientId,
            body: newClient,
            navigate
          })
        ).unwrap();

        setNewClient(client);
        navigate('/clients');
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide code and name',
          type: NotificationType.Error
        })
      );
    }
  };

  useEffect(() => {
    if (clientId) {
      dispatch(getClient({ id: clientId, navigate }))
        .unwrap()
        .then((c) => {
          setNewClient(c);
        });
    }
  }, [clientId, dispatch, navigate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(value);
    setNewClient((prevState) => ({
      ...prevState,
      [name]: value.toString()
    }));
  };

  const cancelUpdate = () => {
    dispatch(resetClientState());
    setNewClient(client);
    navigate('/clients');
  };

  const handleUpdateOrCreate = async () => {
    if (clientId === undefined) {
      return await handleClientCreation();
    } else {
      return await handleClientUpdating();
    }
  };

  return (
    <Box sx={{ maxWidth: 1000, minWidth: 500, margin: '0 auto', padding: 2 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre"
        name="name"
        value={newClient.name}
        onChange={handleChange}
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          select
          margin="normal"
          id="gov_id_type"
          label="Documento"
          name="gov_id_type"
          type="text"
          value={newClient.gov_id_type}
          onChange={handleChange}
          sx={{ flex: 1 }}>
          <MenuItem value="RUT">RUT</MenuItem>
          <MenuItem value="CI">CI</MenuItem>
          <MenuItem value="OTROS">OTROS</MenuItem>
        </TextField>
        <TextField
          margin="normal"
          // required
          fullWidth
          id="gov_id"
          label="Número"
          type="text"
          name="gov_id"
          value={newClient.gov_id}
          onChange={handleChange}
          sx={{ flex: 2 }}
        />
      </Box>
      <TextField
        margin="normal"
        fullWidth
        id="address"
        label="Dirección"
        name="address"
        value={newClient.address}
        onChange={handleChange}
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={cancelUpdate}>
          Cancelar
        </Button>

        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleUpdateOrCreate}>
          {clientId !== undefined ? 'EDITAR' : 'CREAR'}
        </Button>
      </Box>
    </Box>
  );
};

export default Client;
