import { PDFViewer } from '@react-pdf/renderer';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { useEffect } from 'react';
import { getOrder } from '../slices/orderSlice';
import { PdfAdhesivos } from './PDF_Prod_Adhesivos';
import { PdfPoliuretano } from './PDF_Prod_Poliuretano';

const PDF = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const { order } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrder({ options: { order_id: orderId }, navigate: navigate }));
    }
  }, [orderId, dispatch, navigate]);

  // Selección del componente PDF según el tipo
  const renderPDF = () => {
    switch (order?.product.type?.toUpperCase()) {
      case 'A':
        return <PdfAdhesivos />;
      case 'P':
        return <PdfPoliuretano />;
      default:
        return <div>No se encontró un PDF para este tipo de orden</div>;
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh', margin: 0, border: 0, padding: 0 }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>{renderPDF()}</PDFViewer>
    </div>
  );
};

export default PDF;
