/* eslint-disable @typescript-eslint/no-redeclare */
import * as t from 'io-ts';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  GetEndpointInjector,
  GetOneEndpointInjector,
  PostEndpointInjector,
  PutEndpointInjector
} from '../api/httpClient';
import { Options, Paginated, buildPagingOptions } from './paging';
import httpClientV2 from '../api/httpClient.v2';

const ClientPost = t.type({
  name: t.string,
  gov_id_type: t.string,
  gov_id: t.string,
  address: t.string
});

const ClientGet = t.type({
  client_id: t.string,
  code: t.string,
  name: t.string,
  gov_id_type: t.string,
  gov_id: t.string,
  address: t.string
});

export type ClientGet = t.TypeOf<typeof ClientGet>;
export type ClientPost = t.TypeOf<typeof ClientPost>;

const emptyClient: ClientGet = {
  client_id: '',
  name: '',
  gov_id_type: '',
  gov_id: '',
  address: '',
  code: ''
};

export const postClient = createAsyncThunk(
  'clients/post',
  async (deps: PostEndpointInjector<ClientPost>, { dispatch }) => {
    return await httpClientV2.post(`/clients`, {
      body: deps.body,
      returnCodec: ClientGet,
      dispatch,
      navigate: deps.navigate
    });
  }
);

export const putClient = createAsyncThunk(
  'clients/put',
  async (deps: PutEndpointInjector<ClientPost>, { dispatch }) => {
    return await httpClientV2.put(`/clients/${deps.id}`, {
      body: deps.body,
      returnCodec: ClientGet,
      dispatch,
      navigate: deps.navigate
    });
  }
);

export const getClients = createAsyncThunk(
  'clients/get/mul',
  async (deps: GetEndpointInjector<Options>, { dispatch }) => {
    return await httpClientV2.get(`/clients?${buildPagingOptions(deps.options)}`, {
      dispatch,
      navigate: deps.navigate,
      returnCodec: Paginated(ClientGet)
    });
  }
);

export const getClient = createAsyncThunk(
  'clients/get',
  async (deps: GetOneEndpointInjector, { dispatch }) => {
    return await httpClientV2.get(`/clients/${deps.id}`, {
      dispatch,
      navigate: deps.navigate,
      returnCodec: ClientGet
    });
  }
);

export const searchClients = createAsyncThunk(
  'clients/search',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    const rep = await httpClientV2.get(`/search/clients?search_word=${deps.options?.word ?? ''}`, {
      dispatch,
      navigate: deps.navigate,
      returnCodec: t.array(ClientGet)
    });
    return rep;
  }
);

type ErrorResponse = {
  message: string;
};

type ClientApiState = {
  clients?: ClientGet[] | null;
  client: ClientGet;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: ClientApiState = {
  clients: [],
  client: emptyClient,
  status: 'idle',
  page: 1,
  limit: 10,
  count: 0,
  error: null
};

const clientSlide = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.client = emptyClient;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.client = emptyClient;
    },
    resetClientState(state) {
      state.client = emptyClient;
      state.clients = undefined;
      state.status = 'idle';
      state.error = null;
    },
    setClient(state, action: PayloadAction<ClientGet>) {
      console.log(state);
      console.log(action);
      state.client = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getClients.fulfilled, (state, action: PayloadAction<Paginated<ClientGet>>) => {
        state.status = 'idle';
        state.clients = action.payload.items;
        state.count = action.payload.count;
      })
      .addCase(getClients.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postClient.fulfilled, (state, action) => {
        state.status = 'idle';
        setPage(1);
        setLimit(10);
      })
      .addCase(postClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(putClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = undefined;
        state.client = emptyClient;
        setPage(1);
        setLimit(10);
      })
      .addCase(putClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.client = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchClients.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchClients.fulfilled, (state, action: PayloadAction<ClientGet[]>) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(searchClients.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { resetClientState } = clientSlide.actions;
export default clientSlide.reducer;
export const { setPage } = clientSlide.actions;
export const { setLimit } = clientSlide.actions;
export const { setClient } = clientSlide.actions;
