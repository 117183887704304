import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { getFromStorage } from '../helper/storage';
import { MissingAccessTokenError } from '../utils/errors';

export const isValid =
  <A>(codec: t.Decoder<unknown, A>) =>
  (value: unknown): value is A =>
    pipe(
      value,
      codec.decode,
      E.map(() => true),
      E.getOrElse(() => false)
    );

export const getAccessToken = (): string => {
  const val = getFromStorage('userInfo');

  if (val === null || val === undefined) {
    throw new MissingAccessTokenError();
  }

  const jsonVal = JSON.parse(val);

  return jsonVal.access_token;
};
