import {
  Box,
  TextField,
  Button,
  Divider,
  MenuItem,
  SelectChangeEvent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import {
  ProductGet,
  ProductAttributes,
  getProduct,
  postProduct,
  putProduct,
  resetProductState,
  setLimit,
  setPage
} from '../slices/productSlice';
import { getMachines } from '../slices/machineSlice';
import { useNavigate } from 'react-router-dom';
import {
  isErrorStateValidationErrorType,
  updatePropertyByPath,
  updatePropertyByPathV2
} from '../utils/utils';
import { useParams } from 'react-router-dom';
import { getClients, searchClients } from '../slices/clientSlice';
import FilterableSelect from './library/FilterableSelect';
import { getConstants } from '../slices/constantSlice';
import CustomSelect from './library/CustomSelect';

const Product = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { constants } = useAppSelector((state) => state.constant);

  const { productId } = useParams<{ productId: string }>();
  const emptyProduct: ProductAttributes = {
    name: '',
    material: '',
    colors: [],
    dimension: {
      x: 0,
      y: 0
    },
    printing: {
      type: '',
      position_type: '',
      position_value: 0,
      coil: ''
    },
    mother_coil_width: 0,
    print_type: '',
    art_format_x1: 0,
    art_format_x2: 0,
    type: '',
    variation: 0,
    amount_of_colors: 0,
    machine_id: '',
    packaging_data: '',
    // size: {
    //   width: 0,
    //   height: 0
    // },
    client_id: '',
    troquel_num: 0,
    clise_num: 0,
    handle_batch: false,
    handle_expiration: false,
    track_number: 0,
    repetitions_number: 0
  };

  const emptyProductErrorMessageState = {
    name: { error: false, message: undefined },
    material: { error: false, message: undefined },
    colors: { error: false, message: undefined },
    dimension: {
      x: { error: false, message: undefined },
      y: { error: false, message: undefined }
    },
    printing: {
      type: { error: false, message: undefined },
      position_type: { error: false, message: undefined },
      position_value: { error: false, message: undefined },
      coil: { error: false, message: undefined }
    },
    mother_coil_width: { error: false, message: undefined },
    print_type: { error: false, message: undefined },
    art_format_x1: { error: false, message: undefined },
    art_format_x2: { error: false, message: undefined },
    type: { error: false, message: undefined },
    variation: { error: false, message: undefined },
    amount_of_colors: { error: false, message: undefined },
    machine_id: { error: false, message: undefined },
    packaging_data: { error: false, message: undefined },
    // size: {
    //   width: { error: false, message: undefined },
    //   height: { error: false, message: undefined }
    // },
    client_id: { error: false, message: undefined },

    troquel_num: { error: false, message: undefined },
    clise_num: { error: false, message: undefined },

    handle_batch: { error: false, message: undefined },
    handle_expiration: { error: false, message: undefined },
    track_number: { error: false, message: undefined },
    repetitions_number: { error: false, message: undefined }
  };

  type EmptyProductErrorMessageState = typeof emptyProductErrorMessageState;

  const [newProduct, setNewProduct] = useState(emptyProduct);
  const [selectedMachine, setSelectedMachine] = useState({ machine_id: '', name: '' });
  const [productErrorState, setProductErrorstate] = useState(emptyProductErrorMessageState);

  const machines = useAppSelector((state) => state.machine.machines);
  const { clients } = useAppSelector((state) => state.client);

  const handleProductCreation = async () => {
    if (newProduct.name) {
      try {
        const response = await dispatch(postProduct({ body: newProduct, navigate })).unwrap();

        if (response.type !== 'ERROR') {
          setPage(1);
          setLimit(10);
          setNewProduct(emptyProduct);
          navigate('/products');
        } else {
          setProductErrorstate(emptyProductErrorMessageState);
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setProductErrorstate((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                }) as EmptyProductErrorMessageState;
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide value',
          type: NotificationType.Error
        })
      );
    }
  };

  useEffect(() => {
    if (productId) {
      dispatch(getProduct({ id: productId, navigate })).then((prod) => {
        handleNewProductUpdateFromOneResponse(prod.payload);
      });
    }

    dispatch(getConstants({ navigate }));
    dispatch(getClients({ options: { paging: { page: 1, limit: 10 } }, navigate }));
    dispatch(getMachines({ navigate }));
  }, [productId, dispatch, navigate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChangeBase(name, value);
  };

  const handleMachineNameChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const selectedMachine = machines?.items.find((m) => m.name === value);
    if (selectedMachine) {
      setNewProduct({
        ...newProduct,
        machine_id: selectedMachine.machine_id
      });
      setSelectedMachine(selectedMachine);
    }
  };

  const handleNewProductUpdateFromOneResponse = (product: ProductGet) => {
    const productToUpdate: ProductAttributes = {
      ...product.attributes,
      variation: product.variation,
      type: product.type,
      name: product.name,
      client_id: product.client?.client_id,
      machine_id: product.machine.machine_id,
      handle_batch: product.handle_batch,
      handle_expiration: product.handle_expiration,
      clise_num: product.clise_num,
      troquel_num: product.troquel_num,
      material: product.material
    };
    setNewProduct(productToUpdate);
    setSelectedMachine(product.machine);
  };

  const handleProductUpdating = async () => {
    if (newProduct.name && productId !== undefined) {
      try {
        console.log(newProduct);
        const response = await dispatch(
          putProduct({ body: newProduct, id: productId, navigate })
        ).unwrap();

        if (response.type !== 'ERROR') {
          console.log('Updated');
          setPage(1);
          setLimit(10);
          dispatch(resetProductState());
          setNewProduct(emptyProduct);
          navigate('/products');
        } else {
          setProductErrorstate(emptyProductErrorMessageState);
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setProductErrorstate((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                }) as EmptyProductErrorMessageState;
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide value',
          type: NotificationType.Error
        })
      );
    }
  };

  const cancelUpdate = () => {
    dispatch(resetProductState());
    setNewProduct(emptyProduct);
    navigate('/products');
  };

  const handleUpdateOrCreate = async () => {
    if (productId === undefined) {
      return await handleProductCreation();
    } else {
      return await handleProductUpdating();
    }
  };

  const handleSearchChange = (value: string) => {
    if (value === '') {
      dispatch(getClients({ options: { paging: { page: 1, limit: 10 } }, navigate }));
    } else {
      dispatch(searchClients({ options: { word: value }, navigate }));
    }
  };

  const handleSelectChange = async (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    handleChangeBase(name, value);
  };

  const handleChangeBase = (name: string, value: string) => {
    setNewProduct((prev) => updatePropertyByPathV2(prev, name, value));
  };

  const handleColorSelection = (value: string) => () => {
    const currentIndex = newProduct.colors.indexOf(value);
    const newChecked = [...newProduct.colors];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNewProduct((prevValue) => ({
      ...prevValue,
      colors: newChecked,
      amount_of_colors: newChecked.length
    }));
  };

  const handleOtherOptSelection = (value: string) => () => {
    switch (value) {
      case 'lote':
        setNewProduct((prevValue) => ({
          ...prevValue,
          handle_batch: !prevValue.handle_batch
        }));
        break;
      case 'venc':
        setNewProduct((prevValue) => ({
          ...prevValue,
          handle_expiration: !prevValue.handle_expiration
        }));
        break;
      default:
        console.log('unknown');
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 1100,
        minWidth: 300,
        margin: '0 auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiFormControl-root': { m: 1, width: '25ch' }
        }}>
        <div>
          {productId === undefined ? (
            <div>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
                Seleccionar Cliente
              </Divider>
              {FilterableSelect({
                options: clients
                  ? clients.map((c) => ({ id: c.client_id, label: `${c.name} (${c.code})` }))
                  : [],
                label: 'Buscar cliente',
                textInputOnChange: handleSearchChange,
                selectOnChange: (
                  value: {
                    id: string;
                    label: string;
                  } | null
                ) => {
                  if (value) {
                    setNewProduct((prevState) => ({
                      ...prevState,
                      client_id: value.id
                    }));
                  }
                }
              })}
            </div>
          ) : (
            <></>
          )}
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Detalle del producto
        </Divider>
        <div>
          {CustomSelect({
            value: newProduct.type,
            label: 'Tipo',
            list: constants?.product.type.map((c) => ({ key: c, name: c })) ?? [],
            name: 'type',
            onChange: handleSelectChange
          })}

          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            value={newProduct.name}
            onChange={handleChange}
            helperText={productErrorState.name.message}
            error={productErrorState.name.error}
          />
          {CustomSelect({
            value: newProduct.material,
            label: 'Material',
            list: constants?.product.materials.map((c) => ({ key: c, name: c })) ?? [],
            name: 'material',
            onChange: handleSelectChange
          })}

          {/* TODO: Update to select multiple colors */}
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="color"
            label="Color"
            name="color"
            value={newProduct.color}
            onChange={handleChange}
            helperText={productErrorState.color.message}
            error={productErrorState.color.error}
          /> */}

          <TextField
            margin="normal"
            required
            fullWidth
            id="mother_coil_width"
            label="Ancho de la bobina madre"
            name="mother_coil_width"
            type="number"
            value={newProduct.mother_coil_width === 0 ? '' : +newProduct.mother_coil_width}
            onChange={handleChange}
            helperText={productErrorState.mother_coil_width.message}
            error={productErrorState.mother_coil_width.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="troquel_num"
            label="Numero de Troquel"
            name="troquel_num"
            type="number"
            value={newProduct.troquel_num === 0 ? '' : +newProduct.troquel_num}
            onChange={handleChange}
            helperText={productErrorState.troquel_num.message}
            error={productErrorState.troquel_num.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="clise_num"
            label="Numero de Clise"
            name="clise_num"
            type="number"
            value={newProduct.clise_num === 0 ? '' : +newProduct.clise_num}
            onChange={handleChange}
            helperText={productErrorState.clise_num.message}
            error={productErrorState.clise_num.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="variation"
            label="Variacion"
            name="variation"
            type="number"
            value={newProduct.variation === 0 ? '' : newProduct.variation}
            onChange={handleChange}
            helperText={productErrorState.variation.message}
            error={productErrorState.variation.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="track_number"
            label="Numero de pista"
            name="track_number"
            type="number"
            value={newProduct.track_number === 0 ? '' : newProduct.track_number}
            onChange={handleChange}
            helperText={productErrorState.track_number.message}
            error={productErrorState.track_number.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="repetitions_number"
            label="Numero de repeticiones"
            name="repetitions_number"
            type="number"
            value={newProduct.repetitions_number === 0 ? '' : newProduct.repetitions_number}
            onChange={handleChange}
            helperText={productErrorState.repetitions_number.message}
            error={productErrorState.repetitions_number.error}
          />
        </div>

        <div>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
            Otros detalles
          </Divider>

          <List sx={{ width: '100%', maxWidth: '25ch' }}>
            <ListItem key={'maneja-lote'} disablePadding>
              <ListItemButton role={undefined} onClick={handleOtherOptSelection('lote')} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={newProduct.handle_batch}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-maneja-lote' }}
                  />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-maneja-lote'} primary={'Maneja Lote'} />
              </ListItemButton>
            </ListItem>

            <ListItem key={'maneja-venc'} disablePadding>
              <ListItemButton role={undefined} onClick={handleOtherOptSelection('venc')} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={newProduct.handle_expiration}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-maneja-venc' }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={'checkbox-list-label-maneja-venc'}
                  primary={'Maneja Vencimiento'}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>

        <div>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
            Colores
          </Divider>

          <List sx={{ width: '100%', maxWidth: '25ch' }}>
            {constants?.product.colors.map((c) => {
              const labelId = `checkbox-list-label-${c}`;

              return (
                <ListItem key={c} disablePadding>
                  <ListItemButton role={undefined} onClick={handleColorSelection(c)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={newProduct.colors.includes(c)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={c} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>

        {/* <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Formato Arte
        </Divider>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="art_format_x1"
            label="X"
            name="art_format_x1"
            type="number"
            value={newProduct.art_format_x1 === 0 ? '' : newProduct.art_format_x1}
            onChange={handleChange}
            helperText={productErrorState.art_format_x1.message}
            error={productErrorState.art_format_x1.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="art_format_x2"
            label="Y"
            name="art_format_x2"
            type="number"
            value={newProduct.art_format_x2 === 0 ? '' : newProduct.art_format_x2}
            onChange={handleChange}
            helperText={productErrorState.art_format_x2.message}
            error={productErrorState.art_format_x2.error}
          />
        </div> */}

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Maquina
        </Divider>
        <div>
          <TextField
            select
            margin="normal"
            required
            id="machine_id"
            label="Maquina"
            name="machine_id"
            type="text"
            value={selectedMachine.name}
            onChange={handleMachineNameChangeSelect}
            sx={{ flex: 1 }}>
            {(machines ? machines.items : []).map((val) => (
              <MenuItem key={val.machine_id} value={val.name}>
                {val.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Datos de empaquetado
        </Divider>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="packaging_data"
            label="Datos de empaquetado"
            name="packaging_data"
            type="string"
            value={newProduct.packaging_data}
            onChange={handleChange}
            helperText={productErrorState.packaging_data.message}
            error={productErrorState.packaging_data.error}
          />
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Dimensiones
        </Divider>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="dimension.x"
            label="X"
            name="dimension.x"
            type="number"
            value={newProduct.dimension.x === 0 ? '' : newProduct.dimension.x}
            onChange={handleChange}
            helperText={productErrorState.dimension.x.message}
            error={productErrorState.dimension.x.error}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="dimension.y"
            label="Y"
            name="dimension.y"
            type="number"
            value={newProduct.dimension.y === 0 ? '' : newProduct.dimension.y}
            onChange={handleChange}
            helperText={productErrorState.dimension.y.message}
            error={productErrorState.dimension.y.error}
          />
        </div>

        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Impresion
        </Divider>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="printing.position_value"
            label="Valor de la posición"
            name="printing.position_value"
            type="number"
            value={
              newProduct.printing.position_value === 0 ? '' : newProduct.printing.position_value
            }
            onChange={handleChange}
            helperText={productErrorState.printing.position_value.message}
            error={productErrorState.printing.position_value.error}
          />

          {CustomSelect({
            value: newProduct.printing.coil,
            label: 'Bobina',
            list: constants?.product.coil_printing.map((c) => ({ key: c, name: c })) ?? [],
            name: 'printing.coil',
            onChange: handleSelectChange
          })}

          {CustomSelect({
            value: newProduct.printing.type,
            label: 'Tipo',
            list: constants?.product.printing_type.map((c) => ({ key: c, name: c })) ?? [],
            name: 'printing.type',
            onChange: handleSelectChange
          })}

          {CustomSelect({
            value: newProduct.printing.position_type,
            label: 'Posicion',
            list: constants?.product.printing_position.map((c) => ({ key: c, name: c })) ?? [],
            name: 'printing.position_type',
            onChange: handleSelectChange
          })}
        </div>

        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* {productId !== undefined ? ( */}
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={cancelUpdate}>
            Cancelar
          </Button>
          {/* ) : null} */}

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleUpdateOrCreate}>
            {productId !== undefined ? 'EDITAR' : 'CREAR'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Product;
