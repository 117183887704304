import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import GenericTable from '../layouts/PDF/GenericTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { useEffect } from 'react';
import { getOrder } from '../slices/orderSlice';
import * as lines from '../layouts/PDF/Lines';
import { base64Logo } from './utils/pdfIcon';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10
  },
  // header: {
  //   fontSize: 12,
  //   marginBottom: 10,
  //   textAlign: 'center',
  //   fontWeight: 'bold'
  // },
  header: {
    flexDirection: 'row', // Align elements horizontally
    alignItems: 'center', // Align icon and text vertically
    marginBottom: 10
  },
  icon: {
    width: 65, // Adjust the size of the icon
    height: 24,
    marginRight: -100 // Add space between the icon and the text
  },
  headerText: {
    fontSize: 12,
    textAlign: 'center', // Center the text within the space available
    fontWeight: 'bold',
    flex: 1 // Ensure the text takes up the remaining space
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
    fontStyle: 'bold',
    fontFamily: 'Arimo'
  },
  midTitle: {
    fontSize: 10,
    marginBottom: 10,
    marginTop: 10,
    fontStyle: 'bold',
    fontFamily: 'Arimo'
  },
  midSectionTitle: {
    fontSize: 10,
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'center',
    fontStyle: 'bold',
    fontFamily: 'Arimo'
  },
  emptyBox: {
    borderWidth: 1,
    borderColor: '#000',
    height: 50 // Adjust height if needed
  }
});

export const PdfAdhesivos = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const { order } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrder({ options: { order_id: orderId }, navigate: navigate }));
    }
  }, [orderId, dispatch, navigate]);

  return (
    <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0 }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document>
          {order !== undefined && order !== null ? (
            <>
              <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                  <Image src={base64Logo} style={styles.icon} />
                  <Text style={styles.headerText}>Formulario de Producción</Text>
                </View>

                <GenericTable data={lines.line1(order)} />
                <GenericTable data={lines.line2(order)} />
                <GenericTable data={lines.line3(order)} />
                <GenericTable data={lines.line41(order)} />
                <GenericTable data={lines.line42(order)} />
                <GenericTable data={lines.line5(order)} />

                <Text style={styles.midTitle}>Observaciones:</Text>
                <View style={styles.emptyBox} />

                <Text style={styles.midTitle}>Despeje de Máquina</Text>
                <GenericTable data={lines.line6} />

                <GenericTable data={lines.line7} />
                <GenericTable data={lines.line8} />
                <GenericTable data={lines.line9} />
                <GenericTable data={lines.line10} />

                {/* <Text style={styles.midTitle}>Observaciones:</Text>
                <View style={styles.emptyBox} /> */}
              </Page>
              <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                  <Image src={base64Logo} style={styles.icon} />
                  <Text style={styles.headerText}>
                    Orden de refilado para etiquetas autoadhesivas
                  </Text>
                </View>
                <GenericTable data={lines.line11} />

                <Text style={styles.midSectionTitle}>Despeje de Máquina</Text>
                <Text style={styles.midTitle}>Verificacion previa de:</Text>
                <GenericTable data={lines.line12} />

                <Text style={styles.midTitle}>Estado de insumos:</Text>
                <GenericTable data={lines.line13} />

                {/* Removed due to IMPR-12 */}
                {/* <Text style={styles.midSectionTitle}>Preparacion de orden</Text>
                <GenericTable data={lines.line14} /> */}

                <Text style={styles.midSectionTitle}>Datos del refilado</Text>
                <GenericTable data={lines.line15} />
                {/* Removed due to IMPR-12 */}
                {/* <GenericTable data={lines.line16} /> */}

                <Text style={styles.midTitle}>Observaciones:</Text>
                <View style={styles.emptyBox} />
              </Page>
              <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                  <Image src={base64Logo} style={styles.icon} />
                  <Text style={styles.headerText}>
                    Orden de empaquetado para etiquetas autoadhesivas
                  </Text>
                </View>
                <GenericTable data={lines.line11} />

                <Text style={styles.midSectionTitle}>Despeje de area de trabajo</Text>
                <Text style={styles.midTitle}>Verificacion previa de:</Text>
                <GenericTable data={lines.line17} />

                {/* Removed due to IMPR-12 */}
                {/* <GenericTable data={lines.line16} /> */}
                {/* <Text style={styles.midSectionTitle}>Preparacion area de trabajo</Text>
                <Text style={styles.midTitle}>Verificacion previa de:</Text>
                <GenericTable data={lines.line18} /> */}

                <Text style={styles.midSectionTitle}>Datos del empaquetado</Text>
                <GenericTable data={lines.line19} />

                <Text style={styles.midTitle}>Observaciones:</Text>
                <View style={styles.emptyBox} />
              </Page>
            </>
          ) : (
            <Page size="A4" style={styles.page}>
              <View>
                <Text>Orden no encontrada</Text>
              </View>
            </Page>
          )}
        </Document>
      </PDFViewer>
    </div>
  );
};
