import CreateIcon from '@mui/icons-material/Create';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import {
  ProductGet,
  getProducts,
  resetProductState,
  searchProducts,
  setLimit,
  setPage
} from '../slices/productSlice';
import { useNavigate } from 'react-router-dom';
import GenericContainer from '../layouts/GenericContainer';
import GenericTable from '../layouts/GenericTable';
import GenericIconButton from '../layouts/GenericIconButton';

const ProductList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { products, status } = useAppSelector((state) => state.product);
  const currentPage = useAppSelector((state) => state.product.page);
  const currentLimit = useAppSelector((state) => state.product.limit);
  const pagingCount = useAppSelector((state) => state.product.count);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  useEffect(() => {
    dispatch(
      getProducts({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
    );

    return () => {
      dispatch(resetProductState());
    };
  }, [dispatch, navigate, currentPage, currentLimit]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const goToEdit = (id: string) => () => {
    navigate(`/products/${id}`);
  };

  const columns = [
    {
      label: 'Nombre',
      render: (product: ProductGet) => {
        return product.name;
      }
    },
    {
      label: 'Cliente',
      align: 'center' as const,
      render: (product: ProductGet) => {
        return product.client?.name ?? 'No hay cliente';
      }
    },
    {
      label: 'Maquina',
      align: 'center' as const,
      render: (product: ProductGet) => {
        return product.machine.name;
      }
    },
    {
      label: 'Material',
      align: 'center' as const,
      render: (product: ProductGet) => {
        return product.material;
      }
    },
    {
      label: 'Action',
      align: 'center' as const,
      render: (product: ProductGet) => (
        <GenericIconButton onClick={goToEdit(product.product_id)}>
          <CreateIcon fontSize="inherit" />
        </GenericIconButton>
      )
    }
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      dispatch(
        getProducts({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
      );
    } else {
      dispatch(searchProducts({ options: { word: event.target.value }, navigate }));
    }
  };

  const goToNew = () => {
    navigate('/products/new');
  };

  return (
    <GenericContainer status={status}>
      <GenericTable
        columns={columns}
        data={products}
        keyExtractor={(product) => product.product_id}
        pagination={{
          currentPage,
          currentLimit,
          handlePageChange,
          handleChangeRowsPerPage,
          count: pagingCount
        }}
        searchComponent={{
          goToNew,
          handleSearchChange
        }}
        title="Listado de productos"
      />
    </GenericContainer>
  );
};

export default ProductList;
