import * as t from 'io-ts';
import axios, { AxiosHeaders } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { logger } from './logger';
import { getAccessToken, isValid } from '../slices/utils';
import { handleErrorResponseV2 } from './httpHandleError';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { MalformedAPIResponseError } from '../utils/errors';

type ReturnCodec<T> = {
  returnCodec: t.Decoder<unknown, T>;
};

export type MinEndpointDependencies = {
  navigate: NavigateFunction;
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>;
};

export type PostEndpointInjector<T> = {
  body: Object;
} & MinEndpointDependencies &
  ReturnCodec<T>;

export type PutEndpointInjector<T> = {
  body: Object;
} & MinEndpointDependencies &
  ReturnCodec<T>;

export type GetEndpointInjector<T> = {
  options?: Object | undefined;
} & MinEndpointDependencies &
  ReturnCodec<T>;

const addAccessTokenHeader = (urlPath: string): AxiosHeaders => {
  if (urlPath.includes('login')) {
    return new AxiosHeaders();
  } else {
    return new AxiosHeaders({ access_token: getAccessToken() });
  }
};

const decode = <T>(item: unknown, codec: t.Decoder<unknown, T>) => {
  if (isValid(codec)(item)) {
    return item;
  } else {
    throw new MalformedAPIResponseError();
  }
};

export const HttpClientFactory = ({ baseURL }: { baseURL: string | undefined }) => {
  const httpClient = axios.create({ baseURL });

  return {
    get: async <T>(
      url: string,
      { dispatch, navigate, returnCodec }: GetEndpointInjector<T>
    ): Promise<T> => {
      logger.debug(`Request: GET`, url);
      try {
        const response = await httpClient.get(url, { headers: addAccessTokenHeader(url) });
        logger.debug('Response:', response.data);

        return decode(response.data, returnCodec);
      } catch (error) {
        handleErrorResponseV2({ error, dispatch, navigate });
        throw error;
      }
    },
    post: async <T>(
      url: string,
      { body, dispatch, navigate, returnCodec }: PostEndpointInjector<T>
    ): Promise<T> => {
      logger.debug(`Request: POST`, url);
      logger.debug('Body:', body);
      try {
        const response = await httpClient.post(url, body, { headers: addAccessTokenHeader(url) });
        logger.debug('Response:', response.data);

        return decode(response.data, returnCodec);
      } catch (error) {
        handleErrorResponseV2({ error, dispatch, navigate });
        throw error;
      }
    },
    put: async <T>(
      url: string,
      { body, dispatch, navigate, returnCodec }: PutEndpointInjector<T>
    ): Promise<T> => {
      logger.debug(`Request: PUT`, url);
      logger.debug('Body:', body);
      try {
        const response = await httpClient.put(url, body, { headers: addAccessTokenHeader(url) });
        logger.debug('Response:', response.data);

        return decode(response.data, returnCodec);
      } catch (error) {
        handleErrorResponseV2({ error, dispatch, navigate });
        throw error;
      }
    }
  };
};

const httpClientV2 = HttpClientFactory({
  baseURL: process.env.REACT_APP_API_URL
});
export type HttpClientV2 = ReturnType<typeof HttpClientFactory>;

export default httpClientV2;
