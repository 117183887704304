import { Box, Button, SelectChangeEvent, Modal, Typography, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import {
  getOrders,
  setPage,
  setLimit,
  putOrder,
  OrderGetAll,
  searchOrders,
  resetOrderState
} from '../slices/orderSlice';
import React from 'react';
import CustomSelect from './library/CustomSelect';
import { useNavigate } from 'react-router-dom';
import { getConstants } from '../slices/constantSlice';
import GenericContainer from '../layouts/GenericContainer';
import GenericTable from '../layouts/GenericTable';
import GenericIconButton from '../layouts/GenericIconButton';
import CreateIcon from '@mui/icons-material/Create';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { logger } from '../api/logger';
import formatDate from './utils/utils';

const OrderList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { constants, status } = useAppSelector((state) => state.constant);
  const orders = useAppSelector((state) => state.order.orders);
  const currentPage = useAppSelector((state) => state.order.page);
  const currentLimit = useAppSelector((state) => state.order.limit);
  const count = useAppSelector((state) => state.order.count);
  const [open, setOpen] = useState(false);
  const [orderToUpdate, setOrderToUpdate] = useState(orders ? orders[0] : undefined);

  useEffect(() => {
    dispatch(
      getOrders({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
    );
    dispatch(getConstants({ navigate }));

    return () => {
      dispatch(resetOrderState());
    };
  }, [dispatch, navigate, currentPage, currentLimit]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  const handleOrderUpdate = async () => {
    if (orderToUpdate !== undefined) {
      try {
        const response = await dispatch(
          putOrder({
            body: { order_id: orderToUpdate.order_id, status: orderToUpdate.status },
            navigate
          })
        ).unwrap();

        if (response.type !== 'ERROR') {
          setPage(0);
          setLimit(10);
          handleClose();
          dispatch(
            getOrders({
              options: { paging: { page: currentPage, limit: currentLimit } },
              navigate
            })
          );
        } else {
          console.error(response);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide all fields',
          type: NotificationType.Error
        })
      );
    }
  };

  const handleOpen = (order_id: string) => () => {
    const order = orders?.find((o) => o.order_id === order_id);
    setOrderToUpdate(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderToUpdate(undefined);
    setOpen(false);
  };

  const handleOrderToUpdateChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    logger.debug(name);
    logger.debug(value);

    setOrderToUpdate((prevState) => {
      if (prevState !== undefined) {
        return {
          ...prevState,
          [name]: value
        };
      }
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4
  };

  const columns = [
    {
      label: 'Fecha creacion',
      render: (order: OrderGetAll) => formatDate(order.created_at)
    },
    {
      label: 'Order de compra',
      align: 'center' as const,
      render: (order: OrderGetAll) => order.purchase_order
    },
    {
      label: 'Lote',
      align: 'center' as const,
      render: (order: OrderGetAll) => order.internal_batch
    },
    {
      label: 'Cantidad',
      align: 'center' as const,
      render: (order: OrderGetAll) => order.quantity
    },
    {
      label: 'Estado',
      align: 'center' as const,
      render: (order: OrderGetAll) => getCorrectStatusChip(order.status)
    },
    {
      label: '',
      align: 'center' as const,
      render: (order: OrderGetAll) => (
        // <Button
        //   size="small"
        //   sx={{ height: 30 }}
        //   variant="outlined"
        //   color="success"
        //   onClick={handleOpen(order.order_id)}>
        //   Actualizar
        // </Button>

        <GenericIconButton onClick={handleOpen(order.order_id)}>
          <CreateIcon fontSize="medium" />
        </GenericIconButton>
      )
    },
    {
      label: '',
      align: 'center' as const,
      render: (order: OrderGetAll) => (
        <div>
          <GenericIconButton
            onClick={async () => {
              const response = await dispatch(
                putOrder({
                  body: { order_id: order.order_id, status: 'EN PROGRESO' },
                  navigate
                })
              ).unwrap();

              if (response.type !== 'ERROR') {
                setPage(0);
                setLimit(10);
                handleClose();
                dispatch(
                  getOrders({
                    options: { paging: { page: currentPage, limit: currentLimit } },
                    navigate
                  })
                );
              } else {
                console.error(response);
              }

              window.open(`/pdf/${order.order_id}`, '_blank');
            }}>
            <PictureAsPdfIcon fontSize="medium" />
          </GenericIconButton>
          {/* {DownloadablePDFButton({ paddingLeft: 15, order })} */}
        </div>
      )
    }
  ];

  const getCorrectStatusChip = (status: string) => {
    if (status === 'COMPLETADO') {
      return <Chip label={status} color="success" />;
    } else if (status === 'EN PROGRESO') {
      return <Chip label={status} color="primary" />;
    } else if (status === 'EN PREPARACION') {
      return <Chip label={status} />;
    } else {
      return <Chip label={status} />;
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      dispatch(
        getOrders({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
      );
    } else {
      dispatch(searchOrders({ options: { word: event.target.value }, navigate }));
    }
  };

  const goToNew = () => {
    navigate('/orders/new');
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {orderToUpdate?.internal_batch ?? ''} - {orderToUpdate?.purchase_order ?? ''}
          </Typography>
          <br></br>
          {CustomSelect({
            value: orderToUpdate?.status ?? '',
            label: 'Estado',
            list: constants?.order.status.map((c) => ({ key: c, name: c })) ?? [],
            name: 'status',
            onChange: handleOrderToUpdateChange
          })}

          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleOrderUpdate}>
            Actualizar
          </Button>
        </Box>
      </Modal>

      <GenericContainer status={status}>
        <GenericTable
          columns={columns}
          data={orders}
          keyExtractor={(order) => order.order_id}
          pagination={{
            currentPage,
            currentLimit,
            handlePageChange,
            handleChangeRowsPerPage,
            count
          }}
          searchComponent={{
            goToNew,
            handleSearchChange
          }}
          title="Listado de ordenes"
        />
      </GenericContainer>
    </>
  );
};

export default OrderList;
