import { OrderGetOne } from '../../slices/orderSlice';

type TableGen = {
  name: string;
  value: string;
  config?: { removeColon?: boolean; removeSecondText?: boolean };
}[][];

export const line1 = (order: OrderGetOne): TableGen => [
  [
    { name: 'Fecha', value: order.created_at },
    { name: 'Op Manual', value: 'Tamara' }
  ]
];

export const line2 = (order: OrderGetOne): TableGen => [
  [
    { name: 'Cliente', value: order.client.name },
    { name: 'Producto', value: order.product.name },
    { name: 'Orden', value: order.purchase_order }
  ],
  [
    { name: 'Código', value: order.client.code },
    { name: 'Cod. Cliente', value: order.client.code },
    { name: 'Lote interno', value: order.internal_batch }
  ]
];

export const line3 = (order: OrderGetOne): TableGen => [
  [
    { name: 'Material', value: order.product.material },
    { name: 'Colors', value: order.product.attributes.colors.join(', ') },
    { name: 'Ancho bobina madre', value: `${order.product.attributes.mother_coil_width}` },
    { name: 'Tratada', value: order.details.treated }
  ]
];

export const line41 = (order: OrderGetOne): TableGen => [
  [
    // { name: 'Producto', value: order.product.name },
    { name: 'Formato Arte', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'X', value: `${order.product.attributes.art_format_x1}` },
    { name: 'Y', value: `${order.product.attributes.art_format_x2}` }
  ],
  [
    {
      name: 'Arte + Scrup (GAP)',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: 'X (g)', value: `${order.art_plus_scrup_gap_x1}` },
    { name: 'Y (g)', value: `${order.art_plus_scrup_gap_x2}` }
  ]
];

export const line42 = (order: OrderGetOne): TableGen => [
  [
    // { name: 'Producto', value: order.product.name },
    { name: 'Impresión', value: 'Externa' },
    { name: 'X no aprx', value: 'UNKNOWN' },
    { name: 'Esp', value: `${order.details.esp}` }
  ],
  [
    { name: 'N. Colores', value: `${order.product.attributes.colors.length}` },
    { name: 'Cantidad', value: `${order.quantity}` },
    { name: 'Pedido en', value: order.ordered_in },
    { name: 'Maquina', value: order.product.machine.name },
    { name: 'Metros reales', value: `${order.real_meters}` },
    { name: 'Scrup (g)', value: `${order.scrup}` }
  ]
];

export const line5 = (order: OrderGetOne): TableGen => [
  [
    { name: 'Double Faz', value: `${order.details.duplex}m` },
    { name: 'Pegado', value: order.product.machine.stuck },
    {
      name: 'Cilindro',
      value: `${order.product.machine.cylinder} - ${order.product.machine.cylinder_extra}`
    },
    { name: 'N. Troquel', value: `${order.product.troquel_num}` },
    { name: 'N. Pistas', value: `${order.details.track}` }
  ],
  [
    { name: 'Metros lineales (g)', value: `${order.linear_meters}` },
    {
      name: 'Cant etiquetas por m. lineal (g)',
      value: `${order.quantity_of_lables_per_meter_line}`
    },
    {
      name: 'Variación (g)',
      value: `${order.product.variation}`
    },
    { name: 'N. Clisé', value: `${order.product.clise_num ?? '-'}` },
    { name: 'Repeticiones', value: `${order.details.repetitions}` }
  ]
];

export const line6: TableGen = [
  [
    { name: 'Buje', value: ' ' },
    { name: 'Material', value: ' ' },
    { name: 'Tintas', value: ' ' },
    { name: 'Barniz', value: ' ' },
    { name: 'Raclas', value: ' ' },
    { name: 'Clisés', value: ' ' },
    { name: 'Troquel', value: ' ' },
    { name: 'Cinta double faz', value: ' ' }
  ]
];

export const line7: TableGen = [
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Fecha', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hs inicio', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hs final', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Operario', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],

  [
    { name: 'Pegado', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    {
      name: 'Montaje de Maquina',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    {
      name: 'AJuste de las Tintas',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: 'Peso Bobina', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ]
];

export const line8: TableGen = [
  [
    { name: 'Modulo Maquina', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Color', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'PRoveedor', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Anilox', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Racia', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Tinta Mix', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Proporcion', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Peso Inicial', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Peso Final', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],

  [
    { name: '1', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'BLANCO', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Dakral', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Si', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: '2', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: '374 C', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Dakral', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Si', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: '3', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Barniz UV LUM', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Dakral', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Si', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ]
];

export const line9: TableGen = [
  [
    { name: 'Maquinista', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Fecha', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hora Inicio', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hora Fin', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Cod. bob. Master',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: 'Vel.', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Scrup del ajuste',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: 'Metros totales', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Cod. bob. interno',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ]
];

export const line10: TableGen = [
  [
    { name: 'Material impreso en Kgs', value: ' ' },
    { name: 'Rejilla', value: ' ' },
    { name: 'Rralizado por', value: ' ' }
  ]
];

// Page 2
export const line11: TableGen = [
  [
    { name: 'Producto', value: ' ' },
    { name: 'Lote iterno', value: ' ' },
    { name: 'Orden de trabajo', value: ' ' },
    { name: 'Fecha de orden', value: ' ' }
  ],
  [
    { name: 'Cliente', value: ' ' },
    { name: 'Codigo Cliente', value: ' ' },
    { name: 'Cantidad solicitada', value: ' ' },
    { name: 'N. de pistas', value: ' ' }
  ],
  [
    { name: 'Equipo', value: ' ' },
    { name: 'Etiquetas por metro linealrefilado', value: ' ' },
    { name: 'Etiquetas por rollo', value: ' ' },
    { name: 'Metros por rollo', value: ' ' },
    { name: 'Etiquetas por metro lineal sin refilar', value: ' ' }
  ],
  [
    { name: 'Medida', value: ' ' },
    { name: 'Posicion', value: ' ' },
    { name: 'Operario', value: ' ' }
  ]
];

const fechaYHora = [
  { name: 'Fecha', value: ' ' },
  { name: 'Hora', value: ' ' },
  { name: 'Realizado por', value: ' ' }
];

export const line12: TableGen = [
  [
    { name: 'Pallet libre de material', value: 'SI      NO' },
    { name: 'Mesada libre', value: 'SI      NO' },
    { name: 'Maquina libre', value: 'SI      NO' },
    { name: 'Libre de refile', value: 'SI      NO' }
    // { name: 'Roltulos cajas', value: 'SI      NO' } // Removed due to IMPR-12
  ],
  fechaYHora
];

export const line13: TableGen = [
  [
    { name: 'Bujes', value: 'SI      NO' },
    // Removed due to IMPR-12
    // { name: 'Bolsas PE', value: 'SI      NO' },
    // { name: 'Papel Kraft', value: 'SI      NO' },
    // { name: 'Cajas', value: 'SI      NO' },
    { name: 'Rotulos bujes', value: 'SI      NO' }
  ],
  fechaYHora
];

export const line14: TableGen = [
  [{ name: 'Ajuste Maquina', value: 'SI      NO' }, ...fechaYHora],
  [{ name: 'Cuchillas', value: 'SI      NO' }, ...fechaYHora],
  [{ name: 'Bujes', value: 'SI      NO' }, ...fechaYHora]
];

export const line15: TableGen = [
  [
    { name: 'Operario', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Fecha', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hora de inicio', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Hora de fin', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Cod. Bobina', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Vel.', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Scrup en KG', value: ' ', config: { removeColon: true, removeSecondText: true } }
    // Removed due to IMPR-12
    // {
    //   name: 'Mts. totales de la bobina',
    //   value: ' ',
    //   config: { removeColon: true, removeSecondText: true }
    // }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
    // { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
    // { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ]
];

// Removed due to IMPR-12
export const line16: TableGen = [[{ name: 'Scrup y refile TOTAL', value: '  ' }]];

export const line17: TableGen = [
  [
    { name: 'Sin material (OA)', value: 'SI      NO' },
    { name: 'Mesada libre', value: 'SI      NO' },
    // Removed due to IMPR-12
    // { name: 'Libre de refile', value: 'SI      NO' },
    // { name: 'Libre rotulos bujes (OA)', value: 'SI      NO' },
    { name: 'Otros', value: 'SI      NO' }
  ],
  fechaYHora
];

export const line18: TableGen = [
  [
    { name: 'Bolsas PE', value: 'SI      NO' },
    { name: 'Papel Kraft', value: 'SI      NO' },
    { name: 'Rotulos bujes', value: 'SI      NO' },
    { name: 'Rotulos cajas', value: 'SI      NO' },
    { name: 'Centradores', value: 'SI      NO' },
    { name: 'Otros', value: 'SI      NO' }
  ],
  fechaYHora
];

export const line19: TableGen = [
  fechaYHora,
  [
    { name: 'Caja N.', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Etiquetas por caja',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: 'Rotulado', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Peso (kg)', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: 'Caja N.', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Etiquetas por caja',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    },
    { name: 'Rotulado', value: ' ', config: { removeColon: true, removeSecondText: true } },
    {
      name: 'Peso (kg)',
      value: ' ',
      config: { removeColon: true, removeSecondText: true }
    }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ],
  [
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } },
    { name: ' ', value: ' ', config: { removeColon: true, removeSecondText: true } }
  ]
];
