import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import { logout } from '../slices/authSlice';
import { NavigateFunction } from 'react-router-dom';
import { pipe } from 'fp-ts/lib/function';
import { isBaseError } from './errors';

export const getMessage = (e: any) => {
  console.log(e);

  if (isAxiosError(e) && e.response?.data) {
    return e.response.data.message;
  }

  return 'Something went wrong';
};

export const handleErrorResponse = async ({
  error,
  dispatch,
  navigate
}: {
  error: unknown;
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>;
  navigate: NavigateFunction;
}) => {
  if (isBaseError(error)) {
    if (error.type === 'MISSING_ACCESS_TOKEN_ERROR') {
      console.log('MISSING_ACCESS_TOKEN_ERROR');
      await dispatch(logout()).unwrap();
      navigate('/login');
    } else {
      console.log('UNKNOWN');
      dispatch(
        showNotification({
          message: 'An error occurred',
          type: NotificationType.Error
        })
      );
    }
  } else if (isAxiosError(error) && error.response) {
    console.log('Axios found');

    if (
      (error.response.status && error.response.status === 401) ||
      error.response.data.error_message === 'User not found'
    ) {
      console.log('401 found');

      await dispatch(logout()).unwrap();
      navigate('/login');
    }

    console.log(error.response.data);
    const errorResponse = error.response.data;
    dispatch(
      showNotification({
        message: errorResponse.message,
        type: NotificationType.Error
      })
    );
    return {
      type: 'ERROR',
      data: error.response.data
    };
  } else {
    dispatch(
      showNotification({
        message: 'An error occurred',
        type: NotificationType.Error
      })
    );
  }

  throw error;
};

const ValidationErrorC = t.type({
  data: t.type({
    data: t.array(
      t.type({
        key: t.string,
        message: t.string
      })
    )
  })
});

export type ValidationError = t.TypeOf<typeof ValidationErrorC>;

export const isErrorStateValidationErrorType = (smt: unknown): smt is ValidationError =>
  pipe(
    smt,
    ValidationErrorC.decode,
    E.map((_) => true),
    E.getOrElse((err) => false)
  );

export const getValueWithCorrectType = (val: string) => {
  const num = Number(val);
  if (!Number.isNaN(num)) {
    console.log(num);

    return num;
  }
  console.log(val);

  return val;
};

type GenericErrorState = {
  [key: string]: { error: boolean; message: string | undefined } | GenericErrorState;
};

export const updatePropertyByPath = <T extends GenericErrorState>(
  obj: T,
  path: string,
  value: { error: boolean; message: string | undefined }
): T => {
  const keys = path.split('.');
  const keysWithoutNumber = keys.filter((k) => {
    const n = Number.parseInt(k);
    return Number.isNaN(n);
  });

  console.log(keysWithoutNumber);

  const objValResult: GenericErrorState = keysWithoutNumber.reduce(
    (acc: GenericErrorState, key: string, index: number) => {
      if (acc[key] !== undefined) {
        return acc[key] as GenericErrorState;
      } else {
        return {};
      }
    },
    obj
  );
  console.log(objValResult);
  if (objValResult !== undefined && 'error' in objValResult) {
    if (keysWithoutNumber.length === 1) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: value
      };
    } else if (keysWithoutNumber.length === 2) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: value
        }
      };
    } else if (keysWithoutNumber.length === 3) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: {
            ...(obj[keysWithoutNumber[0]] as GenericErrorState)[keysWithoutNumber[1]],
            [keysWithoutNumber[2]]: value
          }
        }
      };
    } else if (keysWithoutNumber.length === 4) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: {
            ...(obj[keysWithoutNumber[0]] as GenericErrorState)[keysWithoutNumber[1]],
            [keysWithoutNumber[2]]: {
              ...(
                (obj[keysWithoutNumber[0]] as GenericErrorState)[
                  keysWithoutNumber[1]
                ] as GenericErrorState
              )[keysWithoutNumber[2]],
              [keysWithoutNumber[3]]: value
            }
          }
        }
      };
    } else {
      return obj;
    }
  } else {
    return obj;
  }
};

export const updatePropertyByPathV2 = <T>(prev: T, name: string, value: string): T => {
  const keys = name.split('.');
  const updatedProduct = { ...prev };

  let current: any = updatedProduct;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    current[key] = { ...current[key] };
    current = current[key];
  }

  current[keys[keys.length - 1]] = getValueWithCorrectType(value);

  return updatedProduct;
};
