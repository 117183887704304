import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import { logout } from '../slices/authSlice';
import { NavigateFunction } from 'react-router-dom';
import { isBaseError } from '../utils/errors';

export const handleErrorResponseV2 = async ({
  error,
  dispatch,
  navigate
}: {
  error: unknown;
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>;
  navigate: NavigateFunction;
}) => {
  if (isBaseError(error)) {
    if (error.type === 'MISSING_ACCESS_TOKEN_ERROR') {
      console.log('MISSING_ACCESS_TOKEN_ERROR');
      await dispatch(logout()).unwrap();
      navigate('/login');
    } else {
      console.log('UNKNOWN');
      dispatch(
        showNotification({
          message: 'An error occurred',
          type: NotificationType.Error
        })
      );
    }
  } else if (isAxiosError(error) && error.response) {
    console.log('Axios found');

    if (
      (error.response.status && error.response.status === 401) ||
      error.response.data.error_message === 'User not found'
    ) {
      console.log('401 found');

      await dispatch(logout()).unwrap();
      navigate('/login');
    }

    console.log(error.response.data);
    const errorResponse = error.response.data;
    dispatch(
      showNotification({
        message: errorResponse.message,
        type: NotificationType.Error
      })
    );
    return {
      type: 'ERROR',
      data: error.response.data
    };
  } else {
    dispatch(
      showNotification({
        message: 'An error occurred',
        type: NotificationType.Error
      })
    );
  }
};
