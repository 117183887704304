/* eslint-disable @typescript-eslint/no-redeclare */
import * as t from 'io-ts';

export type Options = {
  paging?: PaginationOptions;
};

export const buildPagingOptions = (options?: Options) => {
  if (options && options.paging) {
    return `page=${options.paging.page}&limit=${options.paging.limit}`;
  }

  return 'page=1&limit=5';
};

export type PaginationOptions = {
  page: number;
  limit: number;
};

export const Paginated = <T extends t.Mixed>(itemCodec: T) =>
  t.type({
    items: t.array(itemCodec),
    page: t.number,
    limit: t.number,
    count: t.number
  });

export type Paginated<T> = {
  items: T[];
  page: number;
  limit: number;
  count: number;
};
